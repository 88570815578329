import * as _ from 'lodash-es';

import {
  compactObj,
  decodeQuery,
  encodeQuery,
  InputQueryObj,
  parseInt,
} from 'lib/utils';
import { getHost, NATIVE_VERSION, PLATFORM } from 'lib/env';
import { setLatestReleaseVersion } from 'lib/releaseManager';

const getMessage = (obj: any, path?: string): string | null => {
  if (path) obj = _.get(obj, path);
  if (typeof obj === 'string' && obj.length > 0) return obj;
  return null;
};
const parseErrorMessage = (resData?: any): string | null =>
  getMessage(resData) ||
  // our APIs return the shape: `{ error: { message: '...' } }`
  getMessage(resData, 'error.message') ||
  // external APIs may return other shapes:
  getMessage(resData, 'error') ||
  getMessage(resData, 'message');

export class RequestError<
  ResData = Record<string, any> | string,
> extends Error {
  url: string;
  statusCode: number;
  resData?: ResData;
  method: string;

  constructor(method: string, res: Response, resData?: ResData) {
    super(parseErrorMessage(resData) || `${res.statusText} request error`);
    this.method = method;
    this.url = res.url;
    this.resData = resData;
    this.statusCode = res.status;
  }
}

type RequestParams = {
  url: string;
  method?: string;
  body?: Record<string, unknown> | FormData;
  query?: InputQueryObj;
  headers?: Record<string, unknown>;
  /**
   * Sends the request without waiting for the response.
   * This is useful for analytics tracking before the page unloads.
   * Can ONLY be used with POST requests and JSON bodies.
   * */
  asBeacon?: boolean;
};

export const getCsrfToken = (): string | null => {
  try {
    return typeof window !== 'undefined' && typeof document !== 'undefined'
      ? document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content') || null
      : null;
  } catch {}
  return null;
};

const encodeBody = (
  body: RequestParams['body'],
): [content_type: string | undefined, body: FormData | string | undefined] => {
  // form data cannot have a content-type header
  if (body instanceof FormData) return [undefined, body];
  return ['application/json', body != null ? JSON.stringify(body) : undefined];
};

const toFormFields = (
  /** path to field e.g. 'foo[][bar]' is a path in `{ foo: { bar: 1 }[] }` */
  path: string | null,
  /** same value as JSON, but also allows `File` and `undefined` */
  val: any,
  /** `formData` will be mutated as we recursively iterate all nested values */
  formData = new FormData(),
): FormData => {
  if (val === undefined) return formData;

  if (Array.isArray(val)) {
    for (const el of val) {
      toFormFields(`${path!}[]`, el, formData);
    }
  } else if (_.isPlainObject(val)) {
    for (const key in val) {
      toFormFields(path ? `${path}[${key}]` : key, val[key], formData);
    }
  } else {
    // string, number, boolean, null, File
    formData.append(path!, val ?? '');
  }

  return formData;
};
export const toFormData = (obj: Record<string, any>): FormData => {
  return toFormFields(null, obj);
};

const justReloaded = () => {
  const ts = parseInt(localStorage.getItem('stepful:unauthenticated_reload'));
  return !!ts && Date.now() - ts < 5000;
};
class InfiniteUnauthenticateReloadError extends RequestError {}

export const request = async <ResData = unknown>({
  url,
  method,
  body,
  headers,
  query,
  asBeacon,
}: RequestParams): Promise<ResData> => {
  if (!method) method = body ? 'POST' : 'GET';

  const parsed = new URL(url, getHost());
  if (query)
    parsed.search = encodeQuery({ ...decodeQuery(parsed.search), ...query });

  const isSameOrigin = url.startsWith('/') || parsed.origin === getHost();

  url = parsed.href;

  const [contentType, encodedBody] = encodeBody(body);

  // NOTE: `navigator.sendBeacon` is not supported on very old browsers
  // NOTE: `fetch({ keepalive: true })` can be used instead of `navigator.sendBeacon`, but it's only supported by new browsers
  if (asBeacon) {
    if (contentType !== 'application/json')
      throw new Error('asBeacon must be used with JSON body');
    if (method !== 'POST')
      throw new Error('asBeacon must be used with POST method');
    if (window.navigator?.sendBeacon) {
      window.navigator.sendBeacon(
        url,
        encodedBody != null
          ? new Blob([encodedBody as string], { type: contentType })
          : undefined,
      );
      return undefined as ResData;
    }
  }

  const res = await fetch(url, {
    credentials: isSameOrigin ? 'same-origin' : 'omit',
    method,
    headers: compactObj({
      'content-type': contentType,
      accept: 'application/json',
      ...(isSameOrigin
        ? {
            'x-csrf-token': getCsrfToken(),
            'x-requested-with': 'XMLHttpRequest',
            'x-platform': PLATFORM,
            'x-app-version': NATIVE_VERSION,
          }
        : {}),
      ...(headers || {}),
    }),
    body: encodedBody,
  });

  if (isSameOrigin) {
    const v = res.headers.get('x-release-version');
    if (v) setLatestReleaseVersion(v);
  }

  let resData: ResData | undefined;
  const resType = res.headers.get('content-type');
  try {
    if (resType?.includes('text/plain') || resType?.includes('text/html'))
      resData = (await res.text()) as unknown as ResData;
    else if (resType?.includes('application/json')) {
      resData = await res.json();
    }
  } catch {}

  if (!res.ok) {
    // if user is not authenticated, reload the page.
    if (isSameOrigin && res.status === 401) {
      if (justReloaded()) {
        // we've detected an infinite reload loop!
        // this happens if the server does not perform a redirect,
        // and indicates a bug.
        throw new InfiniteUnauthenticateReloadError(method, res);
      } else {
        console.log('401 error: reloading page...');

        localStorage.setItem(
          'stepful:unauthenticated_reload',
          Date.now().toString(),
        );

        window.location.reload();
      }
    }

    throw new RequestError(method, res, resData);
  }

  return resData!;
};
